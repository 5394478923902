import React from "react";
import { graphql } from 'gatsby';
import TemplateWrapper from "../components/template-wrapper";

export default function cstBlogsPrivacy ({ data }) {
  const { nodes: posts } = data.allMarkdownRemark;
  console.log("Privacy Posts length: " + posts.length);
  const accumulation = "";

  return (
    <div className="palette--cst-site" style={{ textAlign: "center" }}> 
    <TemplateWrapper headerImages = {data.allFile.nodes} accumulation = {accumulation} category={""} >   
      {posts
        .filter(post => post.frontmatter.title.length > 0)
        .map( ({ node: post }) => {
          if (!post || !post.id)
            return "";
          else return (
           <div className="blog-post" key={post.id} >
              <h2 dangerouslySetInnerHTML={{__html: post.frontmatter.title}} />
              <div dangerouslySetInnerHTML={{__html: post.html}} />
            </div>
          );
        })}
      <div style={{paddingBottom: "20rem"}}/>           
    </TemplateWrapper>
    </div>
  );
}

export const pageQuery = graphql`
  query cstBlogsPrivacyQuery {
    allMarkdownRemark(
      filter: { frontmatter: { title: {regex: "/Privacy/"}, categories: { eq: "info" } } },
      sort: { order: DESC, fields: [frontmatter___date] }) {
      nodes {
        id
        html
        fields {
          link
        }
        frontmatter {
          date
          title
          author
          categories
          tags
        }
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "images" }, 
    name: { regex: "/science-society/" } }) 
    {       
      nodes {
        id
        relativePath
        extension
        name
        childImageSharp {
          gatsbyImageData( layout: FULL_WIDTH )
        }
      } 
    }
  }
`;
